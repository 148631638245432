@font-face {
  font-family: oxygen;
  src: url(./fonts/Oxygen-Regular.ttf);
}

@font-face {
  font-family: markazi;
  src: url(./fonts/MarkaziText-VariableFont_wght.ttf);
}

@font-face {
  font-family: montserrat;
  src: url(./fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: dm-serif;
  src: url(./fonts/DMSerifDisplay-Regular.ttf);
}

@font-face {
  font-family: bebas-neue;
  src: url(./fonts/BebasNeue-Regular.ttf);
}

:root {
  --primary-bg-red: #B80924;
  --primary-bg-translucent: rgba(186, 9, 36, 5%);
  --black: #000;
  --card-linear-red: #F7E4E7;
  --white: #fff;
  --card-linear-dark-red: #610413;
  --background-red-shade: #fdeef0a6;
  --fade-grey: rgb(62, 62, 62);


  /*Font sizes*/
  --extra-large-heading: 1.875rem;
  --extra-small-heading: .75rem;
  --large-heading: 1.125rem;
  --larger-heading: 1.5rem;
  --x2-heading: 2.6rem;
  --x3-heading: 3rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.underline {
  display: block;
  background: white;
  height: .2rem;
  width: 1.8rem;
}

.small {
  font-family: montserrat;
  color: var(--primary-bg-red);
  font-weight: 700;
  font-size: var(--extra-small-heading);
}

.border-card {
  border: .2rem solid var(--primary-bg-red);
}

.heading-red {
  color: var(--primary-bg-red);
}

.heading-top-left-line,
.heading-top-center-line {
  position: relative;
}

.heading-top-left-line::after {
  content: "";
  position: absolute;
  left: 0;
  top: -.2rem;
  /* transform: translateX(-50%); */
  height: .2rem;
  width: 2rem;
  background: var(--primary-bg-red);
}

.heading-top-center-line::after {

  content: "";
  position: absolute;
  left: 50%;
  top: -.2rem;
  transform: translateX(-50%);
  height: .2rem;
  width: 2rem;
  background: var(--primary-bg-red);
}

.reveal {
  position: relative;
  opacity: 0;
  z-index: -111;
  /* transition: all 1s; */
}

.reveal.active {
  z-index: 0;
  opacity: 1;
  animation: fade-bottom .7s ease-out;
}

.dynamic-container {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 15px;
}

.main-container {
  width: 85%;
  margin: auto;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  margin-top: 50px;
}

.main-container1 {
  width: 85%;
  margin: auto;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  margin-top:40px;
}

\\ Department SLider


.slider1 {
  position: relative;
  text-align: center;
}
.image-name-container {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  position: absolute;
  top: 75%; /* Center vertically */
  left: 30%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  text-align: center; /* Center the text */
  font-family: serif;
  font-size: 1.1rem;
  font-family: serif;
  font-weight: bold;
  text-shadow: 0 0 10px black, 0 0 15px black;
  align-items: end;
}


.image-name {
  font-size: 1.6em; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: white; /* Change color to contrast with the image */
  padding: 10px; /* Optional: padding around text */
  border-radius: 5px; /* Optional: rounded corners */
}

.slide-image1 {
  width: 100%;
  height:550px; /* Maintain aspect ratio */
  display: block; /* Remove extra space below images */
}


.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem; /* Increase the size of the button */
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border: none;
  cursor: pointer;
  padding: 10px; /* Add some padding */
  z-index: 10; /* Ensure buttons are on top of the image */
}

.prev1 {
  left: 10px; /* Position on the left */
}

.next1 {
  right: 10px; /* Position on the right */
}

.show-menu {
  right: 0% !important;
}


a:hover>.hover-line,
ul li:hover>.hover-line {
  width: 100%;
  height: .1rem;
  display: block;
  background: var(--primary-bg-red);
  transition: all .4s;
}

.heading,
.about-department-container h1 {
  font-family: markazi;
  font-size: var(--x2-heading);
  font-weight: 600;
}

.sub-heading {
  font-family: markazi;
  font-size: var(--extra-large-heading);
  font-weight: 600;
}

ol {
  padding-left: 1.5rem;
  list-style: decimal;
}

ol>li {
  margin-bottom: 1rem;
}

.table td {
  position: relative;
}

.table td:after {
  content: "";
  left: 0%;
  z-index: -1;
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  transition: width .4s;
  background: var(--card-linear-red);
}

.table td:hover:after {
  width: 100% !important;
}

/* .active {
  } */
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* .active.fade-left {
    animation: fade-left 1s ease-in;
  }
  .active.fade-right {
    animation: fade-right 1s ease-in;
  } */
/* @keyframes fade-left {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  } */



.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-17 23:31:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}